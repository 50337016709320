/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        $('.menu-link').click(function(){
          $('.navbar-collapse').toggleClass('in');
          $('.bar').toggleClass('animate');
          $('.cassia-nav').toggleClass('active');
        });

        $( document ).ready(function() {
          $('.fixed-bottom').addClass('ready');
        });

        $(".reserve-btn").click(function(event){
            event.stopPropagation();
            resyWidget.openModal({"venueId":1092,"apiKey":"NLQkaBkqJUgo2fE4GvbE3WqODMYLGd4J","replace":true});
        });

        $('a[rel="relativeanchor"]').click(function(){
            $('html, body').animate({
                scrollTop: $( $.attr(this, 'href') ).offset().top - 100
            }, 500);
            return false;
        });

        (function () {
          $('.hamburger-menu').on('click', function() {
            $('.bar').toggleClass('animate');
            $('.cassia-nav').toggleClass('active');
          })
        })();

        $('.navbar-nav>li>a').click(function(){
            $('.navbar-collapse').toggleClass('in');
            $('.bar').toggleClass('animate');
            $('.cassia-nav').toggleClass('active');
        });

        // Even when the window is resized, run this code.
        $(window).resize(function () {
            // Variables
            var windowHeight = $(window).height();
            // Find the value of 90% of the viewport height
            var ninetypercent = 0.2 * windowHeight;
            $(document).scroll(function () {
                // Store the document scroll function in a variable
                var y = $(this).scrollTop();
                // If the document is scrolled 90%
                if (y > ninetypercent) {
                    // Add the "sticky" class
                    // $('nav').addClass('sticky');

                } else {
                    // Else remove it.
                    // $('nav').removeClass('sticky');
                }
            });

            // Call it on resize.
        }).resize();

        $(".gallery-img").unveil(200, function() {
            $(this).load(function() {
                this.style.opacity = 1;
            });
        });

        function inView( opt ) {
            if( opt.selector === undefined ) {
                console.log( 'Valid selector required for inView' );
                return false;
            }
            var elems = [].slice.call( document.querySelectorAll( opt.selector ) ),
                once = opt.once === undefined ? true : opt.once,
                offsetTop = opt.offsetTop === undefined ? 0 : opt.offsetTop,
                offsetBot = opt.offsetBot === undefined ? 0 : opt.offsetBot,
                count = elems.length,
                winHeight = 0,
                ticking = false;

            function update() {
                var i = count;
                while( i-- ) {
                    var elem = elems[ i ],
                        rect = elem.getBoundingClientRect();
                    if( rect.bottom >= offsetTop && rect.top <= winHeight - offsetBot ) {
                        elem.classList.add( 'in-view' );
                        if( once ) {
                            count--;
                            elems.splice( i, 1 );
                        }
                    } else {
                        elem.classList.remove( 'in-view' );
                    }
                }
                ticking = false;
            }

            function onResize() {
                winHeight = window.innerHeight;
                requestTick();
            }

            function onScroll() {
                requestTick();
            }

            function requestTick() {
                if( !ticking ) {
                    requestAnimationFrame( update );
                    ticking = true;
                }
            }
            window.addEventListener( 'resize', onResize, false );
            document.addEventListener( 'scroll', onScroll, false );
            document.addEventListener( 'touchmove', onScroll, false );
            onResize();
        }

        inView({
            selector: '.inview-item', // an .in-view class will get toggled on these elements
            once: false, // set this to false to have the .in-view class be toggled on AND off
            offsetTop: 0, // top threshold to be considered "in view"
            offsetBot: 200 // bottom threshold to be considered "in view"
        });

        jQuery(window).load(function () {
            $('.cassia-nav').fadeIn();
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var imageURLs = [
            templateUrl+"/dist/images/gallery/full/Cassia010.jpg",
            templateUrl+"/dist/images/gallery/full/Cassia013.jpg",
            templateUrl+"/dist/images/gallery/full/Cassia014.jpg",
            templateUrl+"/dist/images/gallery/large/cassia-charcuterie.jpg",
            templateUrl+"/dist/images/gallery/large/cassia-steak-frites.jpg",
            templateUrl+"/dist/images/gallery/large/cassia-clay-oven-bread.jpg",
            templateUrl+"/dist/images/gallery/full/Cassia015.jpg"
        ];
        function getImageTag() {
            var img = '<img src="';
            var randomIndex = Math.floor(Math.random() * imageURLs.length);
            img += imageURLs[randomIndex];
            img += '" class="img-responsive" />';
            return img;
        }
        $('.random-img-wrap').html(getImageTag);




        $('.home-slider').slick({
          // arrows: false,
          dots: false,
          autoplay: true,
          autoplaySpeed: 3000,
          pauseOnHover:false,
          prevArrow: "<div class='prev slider-arrow'><i class='fa fa-chevron-left'></i></i></div>",
          nextArrow: "<div class='next slider-arrow'><i class='fa fa-chevron-right'></i></i></div>",
        });




      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page


      }
    },
    'delivery': {
      init: function() {
        // JavaScript to be fired on the about us page
        sq.caviar.MenuEmbed.transformAll();

      }
    },

    'private_dining': {
      init: function() {
        // JavaScript to be fired on the about us page

        $(document).scroll(function() {
          if ($(this).scrollTop() > 100) {
            $('.center-item').addClass('taller');
          } else {
            $('.center-item').removeClass('taller');
          }
        });

        $dining_slider = $('.dining_slider').slick({
          arrows: false,
        });

        $('.scroll-down').click(function() {
          $dining_slider.slick('slickNext');
        });

        $('.action a[data-slide]').click(function(e) {
          e.preventDefault();
          var slideno = $(this).data('slide');
          $('.dining_slider').slick('slickGoTo', slideno - 0);

          var active_slide = $('.slick-current').data('slick-index');
          $('.action a').removeClass('active');
          $('a[data-slide="'+active_slide+'"]').addClass('active');

        });

        $('.details').click(function(evt) {
          evt.stopPropagation();
          evt.preventDefault();
          console.log('HIII');
          $(this).parents('.item').toggleClass('darken');
          $(this).parent('.center-item').toggleClass('active');
          $(this).text('Hide Details');
          $(this).toggleClass('active');
        });

        $(document).on("click","#menu a", function(e){
          $('.center-item').removeClass('active');
          $('.item').removeClass('darken');
          $('.details').text('View Details');
          $('.details').removeClass('active');
        });

      }
    },

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
